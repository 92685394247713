import React from "react";
import OnlyMobile from "../components/OnlyMobile";
import Payment from "../components/Payment/Payment";

const PaymentPage = () => {
  return (
    <>
      <Payment />
      {/* <OnlyMobile /> */}
    </>
  );
};

export default PaymentPage;
