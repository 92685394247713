import React from "react";
import QRPage from "../components/QRPage";

const QR = () => {
  return (
    <>
      <QRPage />
    </>
  );
};

export default QR;
