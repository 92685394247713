import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import OnlyMobile from "../components/OnlyMobile";
import Page from "../components/Wash/Page";
import axios from "axios";
import getCoordinates from "../components/Wash/getCoordinates";
import { usePosition } from "use-position";

function calculateDistance(lat1, lon1, lat2, lon2, unit) {
  var radlat1 = (Math.PI * lat1) / 180;
  var radlat2 = (Math.PI * lat2) / 180;
  var radlon1 = (Math.PI * lon1) / 180;
  var radlon2 = (Math.PI * lon2) / 180;
  var theta = lon1 - lon2;
  var radtheta = (Math.PI * theta) / 180;
  var dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit == "K") {
    dist = dist * 1.609344;
  }
  if (unit == "N") {
    dist = dist * 0.8684;
  }
  return dist;
}

const Wash = () => {
  const { latitude, longitude, timestamp, accuracy, error } = usePosition();

  const checkUserCoordination = () => {
    if (error) {
      return {
        success: false,
      };
    }

    if (latitude && longitude) {
      return {
        success: true,
        latitude: latitude,
        longitude: longitude,
      };
    }

    return {
      success: false,
    };
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const getWash = async () => {
      const requestWash = async () => {
        const currentLang = localStorage.getItem("lang");
        if (!currentLang) {
          return await axios(`${process.env.REACT_APP_SERVER}/api/wash`);
        } else {
          return await axios(
            `${process.env.REACT_APP_SERVER}/api/wash?locale=${currentLang}`
          );
        }
      };
      const result = await requestWash();

      const newWash = await Promise.all(
        result.data.docs.map(async (item) => {
          return {
            ...item,
            coordinates: await getCoordinates(
              `Украина, ${item.city}, ${item.address}`
            ),
          };
        })
      );

      const userGPS = checkUserCoordination();
      const coordinatesWash = newWash.map((item) => {
        if (userGPS.success) {
          return {
            ...item,
            distance: calculateDistance(
              item.coordinates.lat,
              item.coordinates.lng,
              userGPS.latitude,
              userGPS.longitude,
              "K"
            ),
          };
        } else {
          return {
            ...item,
            distance: 0,
          };
        }
      });

      coordinatesWash.sort(function (a, b) {
        return a.distance - b.distance;
      });
      setData(coordinatesWash);
    };

    getWash();
  }, [latitude]);
  return (
    <>
      <Page data={data} />
      <Footer current="wash" />
      {/* <OnlyMobile /> */}
    </>
  );
};

export default Wash;
