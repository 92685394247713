import React from "react";
import OnlyMobile from "../components/OnlyMobile";
import PaymentPost from "../components/Payment/PaymentPost";

const PaymentPostPage = () => {
  return (
    <>
      <PaymentPost />
      {/* <OnlyMobile /> */}
    </>
  );
};

export default PaymentPostPage;
