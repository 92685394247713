import React from "react";
import MapComponent from "../components/Wash/Maps";
import OnlyMobile from "../components/OnlyMobile";

const Maps = () => {
  return (
    <>
      <div>
        <MapComponent />
      </div>
      {/* <OnlyMobile /> */}
    </>
  );
};

export default Maps;
