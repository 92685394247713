import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ua: {
    translation: {
      "Пост": "Пост",
      "Тут буде ваше Ім’я": "Тут буде ваше Ім’я",
      "Скануй QR-код": "Скануй QR-код",
      "Натисність та наведить на QR-код біля апарату":
        "Натисність та наведить на QR-код біля апарату",
      "Вибери мийку": "Вибери мийку",
      "Поповни рахунок чи проклади маршрут":
        "Поповни рахунок чи проклади маршрут",
      "Мої картки": "Мої картки",
      "Перевір баланс своїх карток клієнта":
        "Перевір баланс своїх карток клієнта",
      Головна: "Головна",
      Акції: "Акції",
      Мийки: "Мийки",
      Історія: "Історія",
      Профіль: "Профіль",
      "Мої карти клієнта": "Мої карти клієнта",
      Так: "Так",
      Ні: "Ні",
      "Ви дійсно бажаєте видалити картку клієнта?":
        "Ви дійсно бажаєте видалити картку клієнта?",
      "Історія мийок": "Історія мийок",
      "На жаль, у Вас ще немає історії мийок":
        "На жаль, у Вас ще немає історії мийок",
      "Виправити це": "Виправити це",
      Поповнення: "Поповнення",
      "Сума поповнення": "Сума поповнення",
      Поповнити: "Поповнити",
      "Поповнити мийку": "Поповнити мийку",
      "Номер авто": "Номер авто",
      "Тут буде номер вашого авто": "Тут буде номер вашого авто",
      "Додати автомобіль": "Додати автомобіль",
      "Введіть новий номер автомобіля, щоб далі користуватися мийками":
        "Введіть новий номер автомобіля, щоб далі користуватися мийками",
      "Використовуючи наш сайт оплати мийки, ви визнаєте та погоджуєтесь з нашою Політикою конфеденційності.": `Використовуючи наш сайт оплати мийки, ви визнаєте та погоджуєтесь
        з нашою Політикою конфеденційності.`,
      "Особисті дані": "Особисті дані",
      "Номер телефону": "Номер телефону",
      "Змінити номер": "Змінити номер",
      "Ім’я": "Ім’я",
      Прізвище: "Прізвище",
      "Тут буде ваше Прізвище": "Тут буде ваше Прізвище",
      "Змінити дані": "Змінити дані",
      "Електронна пошта": "Електронна пошта",
      "Тут буде ваша пошта": "Тут буде ваша пошта",
      "Змінити пошту": "Змінити пошту",
      "Ваш телефон": "Ваш телефон",
      "Введіть новий номер телефону, щоб далі користуватися мийками":
        "Введіть новий номер телефону, щоб далі користуватися мийками",
      "Введіть новий номер": "Введіть новий номер",
      "Використовуючи наш сайт оплати мийки, ви визнаєте та погоджуєтесь з нашою Політикою конфеденційності.": `Використовуючи наш сайт оплати мийки, ви визнаєте та погоджуєтесь
      з нашою Політикою конфеденційності.`,
      Підтвердити: "Підтвердити",
      "Ваші дані": "Ваші дані",
      "Введіть нові дані, щоб далі користуватися мийками":
        "Введіть нові дані, щоб далі користуватися мийками",
      "Введіть нове ім’я": "Введіть нове ім’я",
      "Ваша пошта": "Ваша пошта",
      "Введіть нову адресу електронної пошти, щоб далі користуватися мийками":
        "Введіть нову адресу електронної пошти, щоб далі користуватися мийками",
      "Введіть нову пошту": "Введіть нову пошту",
      Налаштування: "Налаштування",
      "Відповіді на запитання": "Відповіді на запитання",
      "Служба підтримки": "Служба підтримки",
      "Вийти з аккаунту": "Вийти з аккаунту",
      "Видалити аккаунт": "Видалити аккаунт",
      "Ви дійсно бажаєте вийти з аккаунту ?":
        "Ви дійсно бажаєте вийти з аккаунту ?",
      "Ви дійсно бажаєте видалити аккаунт ?":
        "Ви дійсно бажаєте видалити аккаунт ?",
      "Центр підтримки клієнтів": "Центр підтримки клієнтів",
      "Діє з": "Діє з",
      до: "до",
      "Оформити картку": "Оформити картку",
      "Магазин автокосметики та обладнання для автомийок":
        "Магазин автокосметики та обладнання для автомийок",
      "Введіть код з СМС": "Введіть код з СМС",
      Далі: "Далі",
      "Мені не прийшов код": "Мені не прийшов код",
      "Не прийшов код в СМС?": "Не прийшов код в СМС?",
      "Не хвилюйся, тицяй": "Не хвилюйся, тицяй",
      "Відправити ще раз": "Відправити ще раз",
      "Вхід в кабінет": "Вхід в кабінет",
      "Введіть свій номер телефону": "Введіть свій номер телефону",
      "некоректні дані": "некоректні дані",
      "Гортай фото": "Гортай фото",
      "Баланс на мийці": "Баланс на мийці",
      "Помити авто": "Помити авто",
      "Поповнити баланс": "Поповнити баланс",
      "Прокласти маршрут": "Прокласти маршрут",
      Зателефонувати: "Зателефонувати",
      Фільтри: "Фільтри",
      Відчинено: "Відчинено",
      Зачинено: "Зачинено",
      "Відкрито зараз": "Відкрито зараз",
      "Показати результати": "Показати результати",
      "Обери собі найкращу мийку або детейлінг":
        "Обери собі найкращу мийку або детейлінг",
      "НА МАПІ": "НА МАПІ",
      Пилосос: "Пилосос",
      Логін: "Логін",
      "Введіть номер телефону для входу в особистий кабінет":
        "Введіть номер телефону для входу в особистий кабінет",
      "Вхід по номеру": "Вхід по номеру",
      "SMS Пароль надіслано на Ваш мобільний пристрій":
        "SMS Пароль надіслано на Ваш мобільний пристрій",
      "Термін дії одноразового пароля обмежений – 3 хв.":
        "Термін дії одноразового пароля обмежений – 3 хв.",
      "Пароль із SMS": "Пароль із SMS",
      "Ви можете повторно відправити через":
        "Ви можете повторно відправити через",
      "Відправити код ще раз": "Відправити код ще раз",
      "Код було відправлено": "Код було відправлено",
      Успіх: "Успіх",
      "Знайди собі найкращу мийку": "Знайди собі найкращу мийку",
      "Ваші картки клієнта": "Ваші картки клієнта",
      "Знайди мийку поруч з тобою": "Знайди мийку поруч з тобою",
      "Доглядай за авто як професіонал": "Доглядай за авто як професіонал",
      "Акції для кожної мийки Знайди свою та заощаджуй":
        "Акції для кожної мийки Знайди свою та заощаджуй",
      "Знижки від 10% до 50%": "Знижки від 10% до 50%",
      "Доступні акції": "Доступні акції",
      "Знайди найближчу мийку на мапі": "Знайди найближчу мийку на мапі",
      "Доглядай за авто як профі, обирай хімію від виробника":
        "Доглядай за авто як профі, обирай хімію від виробника",
      "Обери мийку на свій смак": "Обери мийку на свій смак",
      "Історія Ваших мийок": "Історія Ваших мийок",
      "Вхід без реєстрації": "Вхід без реєстрації",
      Реєстрація: "Реєстрація",
      "Вибір мови": "Вибір мови",
    },
  },
  en: {
    translation: {
      "Пост": "Post",
      "Тут буде ваше Ім’я": "Your Name will be there",
      "Скануй QR-код": "Scan the QR code",
      "Натисність та наведить на QR-код біля апарату":
        "Clicking and will point to the QR code near the device",
      "Вибери мийку": "Choose a sink",
      "Поповни рахунок чи проклади маршрут":
        "Top up your account or plan a route",
      "Мої картки": "My cards",
      "Перевір баланс своїх карток клієнта":
        "Recheck the balance of your customer cards",
      Головна: "Home",
      Акції: "Actions",
      Мийки: "Wash",
      Історія: "History",
      Профіль: "Profile",
      "Мої карти клієнта": "My customer cards",
      Так: "Yes",
      Ні: "No",
      "Ви дійсно бажаєте видалити картку клієнта?":
        "Are you sure you want to delete the customer card?",
      "Історія мийок": "History Wash",
      "На жаль, у Вас ще немає історії мийок":
        "Unfortunately, you do not yet have a history of sinks",
      "Виправити це": "Fix it",
      Поповнення: "Replenishment",
      "Сума поповнення": "Top-up amount",
      Поповнити: "To replenish",
      "Поповнити мийку": "Replenish wash",
      "Номер авто": "Car number",
      "Тут буде номер вашого авто": "Your car number will be here",
      "Додати автомобіль": "Add a car",
      "Введіть новий номер автомобіля, щоб далі користуватися мийками":
        "Enter a new car number to continue using the car washes",
      "Використовуючи наш сайт оплати мийки, ви визнаєте та погоджуєтесь з нашою Політикою конфеденційності.":
        "By using our car wash payment site, you acknowledge and agree with our Privacy Policy.",
      "Особисті дані": "Personal data",
      "Номер телефону": "Number phone",
      "Змінити номер": "Change number",
      "Ім’я": "First Name",
      Прізвище: "Last Name",
      "Тут буде ваше Прізвище": "Your Surname will be here",
      "Змінити дані": "Change data",
      "Електронна пошта": "Email",
      "Тут буде ваша пошта": "Your mail will be here",
      "Змінити пошту": "Change mail",
      "Ваш телефон": "Your phone",
      "Введіть новий номер телефону, щоб далі користуватися мийками":
        "Enter a new phone number to continue using the sinks",
      "Введіть новий номер": "Enter a new number",
      Підтвердити: "Accept",
      "Ваші дані": "Your Data",
      "Введіть нові дані, щоб далі користуватися мийками":
        "Enter new data to continue using the sinks",
      "Введіть нове ім’я": "Enter a new name",
      "Введіть нове прізвище": "Enter a new last name",
      "Ваша пошта": "Your Mail",
      "Введіть нову адресу електронної пошти, щоб далі користуватися мийками":
        "Enter a new email address to continue using sinks",
      "Введіть нову пошту": "Enter new mail",
      Налаштування: "Settings",
      "Відповіді на запитання": "Answers to questions",
      "Служба підтримки": "Support service",
      "Вийти з аккаунту": "Sign out of the account",
      "Видалити аккаунт": "Delete account",
      "Ви дійсно бажаєте вийти з аккаунту ?": "Do you really want to log out?",
      "Ви дійсно бажаєте видалити аккаунт ?":
        "Do you really want to delete your account?",
      "Центр підтримки клієнтів": "Центр підтримки клієнтів",
      "Діє з": "Works with",
      до: "to",
      "Оформити картку": "Issue a card",
      "Магазин автокосметики та обладнання для автомийок":
        "Car cosmetics and car wash equipment shop",
      "Введіть код з СМС": "Enter the code from SMS",
      Далі: "Next",
      "Мені не прийшов код": "I did not receive the code",
      "Не прийшов код в СМС?": "Did you not receive the SMS code?",
      "Не хвилюйся, тицяй": "Don't worry, keep going",
      "Відправити ще раз": "Send again",
      "Вхід в кабінет": "Entrance to the office",
      "Введіть свій номер телефону": "Enter your phone number",
      "некоректні дані": "incorrect data",
      "Гортай фото": "Flip the photo",
      "Баланс на мийці": "Balance on the sink",
      "Помити авто": "Wash the car",
      "Поповнити баланс": "Top up the balance",
      "Прокласти маршрут": "Make a route",
      Зателефонувати: "To call",
      Фільтри: "Filters",
      Відчинено: "Open",
      Зачинено: "Close",
      "Відкрито зараз": "Open now",
      "Показати результати": "Show results",
      "Обери собі найкращу мийку або детейлінг":
        "Choose the best wash or detailing",
      "НА МАПІ": "MAP",
      Пилосос: "Vacuum cleaner",
      Логін: "Login",
      "Введіть номер телефону для входу в особистий кабінет":
        "Enter the phone number to enter the personal account",
      "Вхід по номеру": "Entry by number",
      "SMS Пароль надіслано на Ваш мобільний пристрій":
        "SMS The password has been sent to your mobile device",
      "Термін дії одноразового пароля обмежений – 3 хв.":
        "The one-time password is limited to 3 minutes.",
      "Пароль із SMS": "Password from SMS",
      "Ви можете повторно відправити через": "You can resend via",
      "Відправити код ще раз": "Submit the code again",
      "Код було відправлено": "The code has been sent",
      Успіх: "Success",
      "Знайди собі найкращу мийку": "Find the best wash for you",
      "Ваші картки клієнта": "Your customer cards",
      "Знайди мийку поруч з тобою": "Find a wash near you",
      "Доглядай за авто як професіонал":
        "Take care of your car like a professional",
      "Акції для кожної мийки Знайди свою та заощаджуй":
        "Promotions for every sink. Find yours and save",
      "Знижки від 10% до 50%": "Discounts from 10% to 50%",
      "Доступні акції": "Available actions",
      "Знайди найближчу мийку на мапі": "Find the nearest car wash on the map",
      "Доглядай за авто як профі, обирай хімію від виробника":
        "Take care of your car like a pro, choose chemicals from the manufacturer",
      "Обери мийку на свій смак": "Choose a car wash to your liking",
      "Історія Ваших мийок": "History of Your Car Washes",
      "Вхід без реєстрації": "Login without registration",
      Реєстрація: "Registration",
      "Вибір мови": "Language selection",
    },
  },
  ru: {
    translation: {
      "Пост": "Пост",
      "Тут буде ваше Ім’я": "Тут будет ваше Имя",
      "Скануй QR-код": "Сканируй QR-код",
      "Натисність та наведить на QR-код біля апарату":
        "Нажмите и наведите на QR-код возле аппарата",
      "Вибери мийку": "Вибери мийку",
      "Поповни рахунок чи проклади маршрут": "Пополни счет или проложи маршрут",
      "Мої картки": "Мои карты",
      "Перевір баланс своїх карток клієнта":
        "Проверь баланс своих карт клиента",
      Головна: "Главная",
      Акції: "Акции",
      Мийки: "Мойки",
      Історія: "История",
      Профіль: "Профиль",
      "Мої карти клієнта": "Мои карты клиента",
      Так: "Да",
      Ні: "Нет",
      "Ви дійсно бажаєте видалити картку клієнта?":
        "Вы действительно хотите удалить карту клиента?",
      "Історія мийок": "История моек",
      "На жаль, у Вас ще немає історії мийок":
        "К сожалению, у Вас еще нет истории моек",
      "Виправити це": "Исправить это",
      Поповнення: "Пополнение",
      "Сума поповнення": "Сумма пополнения",
      Поповнити: "Пополнить",
      "Поповнити мийку": "Пополнить мойку",
      "Номер авто": "Номер авто",
      "Тут буде номер вашого авто": "Здесь будет номер вашего авто",
      "Додати автомобіль": "Добавить автомобиль",
      "Введите новый номер автомобиля, чтобы использовать мойки.":
        "Введите новый номер автомобиля, чтобы использовать мойки.",
      "Особисті дані": "Личные данные",
      "Номер телефону": "Номер телефона",
      "Змінити номер": "Изменить номер",
      "Ім’я": "Имя",
      Прізвище: "Фамилия",
      "Тут буде ваше Прізвище": "Тут будет ваша Фамилия",
      "Змінити дані": "Изменить данные",
      "Електронна пошта": "Электронная почта",
      "Тут буде ваша пошта": "Здесь будет ваша почта",
      "Змінити пошту": "Изменить почту",
      "Ваш телефон": "Ваш телефон",
      "Введіть новий номер телефону, щоб далі користуватися мийками":
        "Введите новый номер телефона, чтобы использовать мойки.",
      "Введіть новий номер": "Введите новый номер",
      "Використовуючи наш сайт оплати мийки, ви визнаєте та погоджуєтесь з нашою Політикою конфеденційності.":
        "Используя наш сайт оплаты мойки, вы признаете и согласны с нашей Политикой конфиденциальности.",
      Підтвердити: "Подтвердить",
      "Ваші дані": "Ваши данные",
      "Введіть нові дані, щоб далі користуватися мийками":
        "Введите новые данные, чтобы использовать мойки.",
      "Введіть нове ім’я": "Введите новое имя",
      "Введіть нове прізвище": "Введите новую фамилию",
      "Ваша пошта": "Ваша почта",
      "Введіть нову адресу електронної пошти, щоб далі користуватися мийками":
        "Введите новый адрес электронной почты для дальнейшего использования мойками",
      "Введіть нову пошту": "Введите новую почту",
      Налаштування: "Настройки",
      "Відповіді на запитання": "Ответы на вопросы",
      "Служба підтримки": "Служба поддержки",
      "Вийти з аккаунту": "Выйти из аккаунта",
      "Видалити аккаунт": "Удалить аккаунт",
      "Ви дійсно бажаєте вийти з аккаунту ?":
        "Вы действительно хотите выйти из аккаунта?",
      "Ви дійсно бажаєте видалити аккаунт ?":
        "Вы действительно хотите удалить аккаунт?",
      "Центр підтримки клієнтів": "Центр поддержки клиентов",
      "Діє з": "Действует с",
      до: "до",
      "Оформити картку": "Оформить карту",
      "Магазин автокосметики та обладнання для автомийок":
        "Магазин автокосметики и оборудования для автомоек",
      "Введіть код з СМС": "Введите код из SMS",
      Далі: "Дальше",
      "Мені не прийшов код": "Мне не пришел код",
      "Не прийшов код в СМС?": "Не пришел ли код в СМС?",
      "Не хвилюйся, тицяй": "Не волнуйся, тыкай",
      "Отправить еще раз": "Отправить еще раз",
      "Вхід в кабінет": "Вход в кабинет",
      "Введіть свій номер телефону": "Введите номер телефона",
      "некоректні дані": "некорректные данные",
      "Гортай фото": "Листай фото",
      "Баланс на мийці": "Баланс на мойке",
      "Помити авто": "Помыть авто",
      "Поповнити баланс": "Пополнить баланс",
      "Прокласти маршрут": "Проложить маршрут",
      Зателефонувати: "Позвонить",
      Фільтри: "Фильтры",
      Відчинено: "Открыто",
      Зачинено: "Закрыто",
      "Відкрито зараз": "Открыто сейчас",
      "Показати результати": "Показать результаты",
      "Обери собі найкращу мийку або детейлінг":
        "Выбери себе лучшую мойку или детейлинг",
      "НА МАПІ": "На КАРТЕ",
      Пилосос: "Пылесос",
      Логін: "Логин",
      "Введіть номер телефону для входу в особистий кабінет":
        "Введите номер телефона для входа в кабинет",
      "Вхід по номеру": "Вход по номеру",
      "SMS Пароль надіслано на Ваш мобільний пристрій":
        "SMS Пароль отправлен на Ваше мобильное устройство",
      "Термін дії одноразового пароля обмежений – 3 хв.":
        "Срок действия одноразового пароля ограничен – 3 мин.",
      "Пароль із SMS": "Пароль с SMS",
      "Ви можете повторно відправити через":
        "Вы можете повторно отправить через",
      "Відправити код ще раз": "Отправить код еще раз",
      "Код було відправлено": "Код был отправлен",
      Успіх: "Успех",
      "Знайди собі найкращу мийку": "Найди себе самую лучшую мойку",
      "Ваші картки клієнта": "Ваши карты клиента",
      "Знайди мийку поруч з тобою": "Найди мойку рядом с тобой",
      "Доглядай за авто як професіонал": "Ухаживай за авто как профессионал",
      "Акції для кожної мийки Знайди свою та заощаджуй":
        "Акции для каждой мойки Найди свою и экономь",
      "Знижки від 10% до 50%": "Скидки от 10% до 50%",
      "Доступні акції": "Доступные действия",
      "Знайди найближчу мийку на мапі": "Найти ближайшую мойку на карте",
      "Доглядай за авто як профі, обирай хімію від виробника":
        "Ухаживайте за автомобилем как профессионал, выбирайте химию от производителя",
      "Обери мийку на свій смак": "Выбери мойку на свой вкус",
      "Історія Ваших мийок": "История ваших моек",
      "Вхід без реєстрації": "Вход без регистрации",
      Реєстрація: "Регистрация",
      "Вибір мови": "Выбор языка",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ua",
  interpolation: {
    escapeValue: true,
  },
});

export default i18n;
