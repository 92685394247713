import React from "react";
import OnlyMobile from "../components/OnlyMobile";
import PaymentNotRegister from "../components/Payment/PaymentNotRegister";

const PaymentNotRegisterPage = () => {
  return (
    <>
      <PaymentNotRegister />
      {/* <OnlyMobile /> */}
    </>
  );
};

export default PaymentNotRegisterPage;
