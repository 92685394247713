import React from "react";
import OnlyMobile from "../components/OnlyMobile";
import NumberPhone from "../components/SingIn/NumberPhone";

const Home = () => {
  document.body.style.background = "#0f84f0";
  return (
    <>
      <NumberPhone />
      {/* <OnlyMobile /> */}
    </>
  );
};

export default Home;
